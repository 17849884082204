import { Link } from "react-router-dom";

const FAQ = () => {
  return (
    <div style={{display: 'grid', placeContent: 'center', padding: '1rem'}}>
      <h2 style={{textAlign: 'center', textDecoration: 'underline'}}>
        Frequently Asked Questions
      </h2>
      <div className='faq'>
        <h3>Where do I get the extension?</h3>
        <p>You can get the extension here: <a href="https://chrome.google.com/webstore/detail/youtube-notes/ildeidpnegphiiolihldpgkgfodoknpc">YouTubeNotes extension</a></p>
      </div>
      <div className='faq'>
        <h3>Do you I need an account to use the browser extension?</h3>
        <p>No you do not, but you won't be able to share notes with others or received shared notes.</p>
        <p>You also lose the protection of having your notes in the cloud and being able to sync them across devices/browsers</p>
      </div>
      <div className='faq'>
        <h3>How does sync work?</h3>
        <p>All data stored in the cloud is pushed to you machine when you login.</p>
        <p>So whether you have a new machine, your using a new supported browser, or your friend's laptop, we store your notes in the browser you logged in from</p>
      </div>
      <div className='faq'>
        <h3>Are notes shared with me counted against my quota?</h3>
        <p>No they are not. They go against the quota of the owner of the note</p>
      </div>
      <div className='faq'>
        <h3>What browser's do you support?</h3>
        <p>Currently we are hosted in the Chrome web store but we support:</p>
        <ul>
          <li>Chrome</li>
          <li>Brave</li>
          <li>Opera</li>
          <li>MS Edge</li>
        </ul>
      </div>
      <div className='faq'>
        <h3>How do exit the search screen or any other screen, I don't see a way to exit?</h3>
        <p>Just press the ESC button on your keyboard to exit any of our screens or any exit buttons that may be on the screen. ESC is the universal way out</p>
      </div>
      <div className='faq'>
        <h3>Will my free account store my notes in the cloud and can I share them?</h3>
        <p>Yes until you hit the free limit. All notes, including the ones stored in the cloud, will be saved in your browser</p>
        <p>You can share notes in the cloud (the ones with a green star <span style={{color: 'green'}}>*</span> or border) and reshare notes shared with you</p>
        <p>If you decide to upgrade at a later date, any new notes after the upgrade will be stored in your account and counted against your quota</p>
      </div>
      <div className='faq'>
        <h3>I want to upgrade from a plan with less data to one with more, how do I do this?</h3>
        <p>From the extension click on the "Manage Account" -&gt; "Cancel subscription". Don't worry we keep your data when upgrading for 7 days from the action.</p>
        <p>Once your subscription is cancelled, go to <Link to='/create-account'>Create Account</Link> page and select the plan you want. Just click on the link that says: "Already have an account, just want to change plan" instead of the button in the plan to upgrade</p>
        <p>All your existing data will be counted against your new quota</p>
      </div>
      <div className='faq'>
        <h3>I want to downgrade my account back down to a cheaper plan. What do I do next?</h3>
        <p>It's the same as above but with a cheaper plan. Please note that some of your notes in the cloud will be deleted (from the cloud not your browser) to match the new quota</p>
      </div>
      <div className='faq'>
        <h3>I want to downgrade my account back down to free. What do I do next?</h3>
        <p>Just cancel your subscription from the "Manage Account" -&gt; "Cancel subscription" and your set. Your data exceeding the free limit will remain on our servers for 7 days, at which point it will be deleted (from the cloud not your browser). </p>
        <p>You can always re-create a paid plan within those 7 days to maintain your data on our servers.</p>
        <p>Please note that we DO NOT delete data from your machine so any notes you have taken are safe as long as you DO NOT clear the browser data.</p>
        <p>Also note, that you can only share notes on our server.</p>
      </div>
      <div className='faq'>
        <h3>I've never created an account and I'm just adding notes from YouTube to my machine, will the notes stay on my machine?</h3>
        <p>Yes, they will stay on your machine forever unless you delete your browser data or your machine gets destroyed some how.</p>
      </div>
      <div className='faq'>
        <h3>What's with the different colored stars and borders, what do they mean?</h3>
        <ul>
          <li>Green stars <span style={{color: 'green'}}>*</span> and <span style={{color: 'green'}}>borders</span> are notes that belong to you and are on the server/cloud</li>
          <li>Purple stars <span style={{color: 'purple'}}>*</span> and <span style={{color: 'purple'}}>borders</span> are notes that were shared with you by others</li>
          <li>Red stars <span style={{color: 'red'}}>*</span> and <span style={{color: 'red'}}>borders</span> are notes that were saved to your machine when you were logged out or were once in the cloud but no longer there.</li>
        </ul>
      </div>

    </div>
  );
};

export default FAQ;
