import { useState } from "react";
import { Pricing, Plan } from "../../components/Pricing/Pricing";
import * as Constants from '../../utils/Constants';
import { RegisterCaptchaWrapper } from "../RegisterCaptchaWrapper/RegisterCaptchaWrapper";

const planMapping = new Map<number, string>();

planMapping.set(0, '#');
planMapping.set(30, process.env.NODE_ENV === 'production' ? Constants.PRODUCTION_THIRTY_DOLLAR_PLAN : Constants.TEST_THIRTY_DOLLAR_PLAN);
planMapping.set(15, process.env.NODE_ENV === 'production' ? Constants.PRODUCTION_FIFTEEN_DOLLAR_PLAN : Constants.TEST_FIFTEEN_DOLLAR_PLAN);
planMapping.set(5, process.env.NODE_ENV === 'production' ? Constants.PRODUCTION_FIVE_DOLLAR_PLAN : Constants.TEST_FIVE_DOLLAR_PLAN);

export function Plans() {
    const [plan, setPlan] = useState<Plan>(0);
    const [planWording, setPlanWording] = useState<string>('Register');
    const [canShow, setCanShow] = useState<boolean>(false);

    window._paq.push(['trackPageView']);

    const onPlanChosen = (chosenPlan: Plan) => {
        setPlan(chosenPlan);
        setCanShow(true);
        setPlanWording(chosenPlan > 0 ? `Register and Pay $${chosenPlan}` : "Register");

        const plan = `Viewing_Plan_${chosenPlan.toString()}`;

        window._paq.push(['trackEvent', 'PlanView', plan]);

        (document.querySelector('.backdrop') as HTMLElement).style.display = 'block';
    };

    const onClose = () => {
        setCanShow(false);

        (document.querySelector('.backdrop') as HTMLElement).style.display = 'none';
    }

    if(!canShow) {
        (document.querySelector('.backdrop') as HTMLElement).style.display = 'none';
    }

    return (
        <>
            <div className="centered-grid pricing-centered">
                <h1 className="plans-pricing">Plans &amp; Pricing</h1>
                <div className="plan-grid">
                    <Pricing
                        title="Simple"
                        price="Free"
                        cloudStorage="500kb"
                        hasSyncFeature={false}
                        planForPricing={0}
                        link={undefined}
                        onPlanChosen={onPlanChosen} />
                    <Pricing
                        title="Basic"
                        price="$5.00 monthly"
                        cloudStorage="1gb"
                        hasSyncFeature={true}
                        planForPricing={5}
                        link={planMapping.get(5)!}
                        onPlanChosen={onPlanChosen} />
                    <Pricing
                        title="Standard"
                        price="$15.00 monthly"
                        backgroundColor="#a9ecc0"
                        cloudStorage="4gb"
                        hasSyncFeature={true}
                        planForPricing={15}
                        link={planMapping.get(15)!}
                        onPlanChosen={onPlanChosen} />
                    <Pricing
                        title="Premium"
                        price="$30.00 monthly"
                        cloudStorage="7gb"
                        hasSyncFeature={true}
                        planForPricing={30}
                        link={planMapping.get(30)!}
                        onPlanChosen={onPlanChosen} />
                    {canShow ? <RegisterCaptchaWrapper plan={plan} planWording={planWording} link={planMapping.get(plan)!} onClose={onClose}/> : null}
                </div>
            </div>
        </>
    );
};
