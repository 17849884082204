import * as SvgConstants from '../../utils/SVGConstants';

export type Plan = 0 | 5 | 15 | 30;

type PricingProps = {
    title: string,
    price: string, 
    backgroundColor?: string,
    cloudStorage: string, 
    hasSyncFeature: boolean,
    planForPricing: Plan,
    link: string | undefined,
    onPlanChosen: (plan: Plan) => void
};

export function Pricing({title, price, backgroundColor, cloudStorage, hasSyncFeature, planForPricing, link, onPlanChosen}: PricingProps) {
    return (
        <>
            <div className="plan-explainer" style={{background: backgroundColor}}>
                    <div className="plan-explainer-title">{title}</div>
                    <h2 className="plan-explainer-price">{price}</h2>
                    <div className="plan-explainer-features">
                        <div className="replaced-element-container small-svg">
                            {SvgConstants.CHECK_MARK}
                        </div>
                        <span className="plan-explainer-text"><span className="caveats">Unlimited</span> storage of notes on computer</span>
                    </div>
                    <div className="plan-explainer-features">
                        <div className="replaced-element-container small-svg">
                            {SvgConstants.CHECK_MARK}
                        </div>
                        <span className="plan-explainer-text"><span className="caveats">Unlimited</span> sharing with friends/family</span>
                    </div>
                    <div className="plan-explainer-features">
                        <div className="replaced-element-container small-svg">
                            {SvgConstants.CHECK_MARK}
                        </div>
                        <span className="plan-explainer-text">Cloud Storage {cloudStorage}</span>
                    </div>
                    <div className="plan-explainer-features">
                        <div className="replaced-element-container small-svg">
                            {SvgConstants.CHECK_MARK}
                        </div>
                        <span className="plan-explainer-text">Sync across devices/browsers</span>
                    </div>
                    {link ? <a href={link}>Already have an account, just want to change plan</a> : null}
                    <button className="plan-explainer-buy-btn" onClick={() => onPlanChosen(planForPricing)}>Sign up</button>
                </div>
        </>
    );
}
