export const PRODUCTION_THIRTY_DOLLAR_PLAN = 'https://buy.stripe.com/bIY7tPb6lgAievmeUU'
export const PRODUCTION_FIFTEEN_DOLLAR_PLAN = 'https://buy.stripe.com/4gwbK5eixbfY9b29AB';
export const PRODUCTION_FIVE_DOLLAR_PLAN = 'https://buy.stripe.com/00gg0l3DT5VE1IA28a';

export const TEST_THIRTY_DOLLAR_PLAN = 'https://buy.stripe.com/test_bIY29XgYm32V4GAcMM'
export const TEST_FIFTEEN_DOLLAR_PLAN = 'https://buy.stripe.com/test_6oE3e1eQe32V6OIaEF';
export const TEST_FIVE_DOLLAR_PLAN = 'https://buy.stripe.com/test_dR66qd8rQbzr7SM9AC';

export const URL = process.env.NODE_ENV === 'production' ? 'https://browserext.com' : 'http://localhost:3000';

export const POST_CREATE_ACCT = URL + "/create-account";
export const POST_RECOVER_ACCT = URL + "/recover";
export const POST_PROCESS_RECOVERY_REPLACE = URL + "/process-recovery/CODE";
