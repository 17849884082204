import { ReactNode, useState, MouseEvent } from "react";
import youTubeLogo from '../../images/youtube-logo.png';
import { Link } from "react-router-dom";

function Layout(props: { children: ReactNode }) {
    const [showLinks, setShowLinks] = useState<boolean>();

    let linksClasses = "mobile-links hide";

    if(showLinks) {
        linksClasses = "mobile-links";
    }

    return (
        <>
            <main>
                <header>
                    <Link className="no-decoration-anchor" to="/">
                        <div className="logo">
                            <div className="replaced-element-container">
                                <img src={youTubeLogo} alt="YouTube logo" />
                            </div>
                            <h1 className="header-row">YouTube Notes</h1>
                        </div>
                    </Link>
                    <nav className="desktop">
                        <ul>
                            <li><Link to="/create-account">Create Account</Link></li>
                            <li><Link to="/faq">FAQ</Link></li>
                            <li><a href="mailto:nitoris.llc@gmail.com">Support</a></li>
                        </ul>
                    </nav>
                    <nav className="mobile">
                        <div className="mobile-link-show" onClick={() => {
                            setShowLinks(state => !state);
                        }}>
                            <div className="mobile-line"></div>
                            <div className="mobile-line"></div>
                            <div className="mobile-line"></div>
                        </div>
                        <div className={linksClasses}>
                            <ul>
                                <li><Link to="/create-account">Create Account</Link></li>
                                <li><Link to="/faq">FAQ</Link></li>
                                <li><a href="mailto:nitoris.llc@gmail.com">Support</a></li>
                            </ul>
                        </div>
                    </nav>

                </header>
                {props.children}
            </main>
            <footer>
                YouTube Notes
            </footer>
        </>
    );
}

export default Layout;
