import { useState } from "react";
import { useParams } from "react-router-dom";

import * as Constants from '../../utils/Constants';

type ErrorResponse = {
    error: string
};

type SuccessResponse = {
    _id: string,
    email: string,
    password: string,
    firstName?: string,
    lastName?: string,
    lastLogin: Date,
    dateJoined: Date,
    isActive: boolean,
    accountClosed: boolean,
    closeDate?: Date,
    tier?: 1 | 2 | 3 | 4 | 5,
    spaceUsed?: number
};

export function ProcessRecovery() {
    const [password, setPassword] = useState<string>('');
    const [hasNotification, setHasNotification] = useState<boolean>(false);
    const [notificationText, setNotificationText] = useState<string>('Password must be 5 characters long');
    const [color, setColor] = useState<string>('red');

    //https://dev.to/javila35/react-router-hook-useparam-now-w-typescript-m93
    const { code } = useParams();

    async function onClick() {
        setHasNotification(false);

        if(!password || password.length < 5) {
            setHasNotification(true);
            setNotificationText('Password must be 5 characters long');
            setColor('red');

            return;
        }

        const response = await fetch(Constants.POST_PROCESS_RECOVERY_REPLACE.replace('CODE', code!), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ password, code })
        });
        const result: ErrorResponse | SuccessResponse = await response.json();

        if('error' in result) {
            setHasNotification(true);
            setNotificationText(result.error);
            setColor('red');
        }
        else {
            setHasNotification(true);
            setNotificationText('Password reset, try to login in now with new password');
            setColor('green');
        }
    };

    let classes = 'notification hide';
    
    if(hasNotification) {
        classes = 'notification';
    }

    return (
        <div className="form-control-recovery">
            <div className={classes} style={{color}}>{notificationText}</div>
            <label htmlFor="password">New Password</label>
            <input type="password" name="password" id="password" value={password} onChange={evt => setPassword(evt.target.value)} />
            <button className="recovery-button" onClick={onClick}>Reset Password</button>
        </div>
    );
}