import Layout from './containers/Layout/Layout';
import { Routes, Route } from 'react-router-dom';

import HomePage from './containers/HomePage/HomePage';
import { Plans } from './containers/Plans/Plans';
import { SucessPage } from './containers/Success/Sucess';
import { FailPage } from './containers/Failed/Failed';
import { InvalidCodePage } from './containers/Failed/InvalidCode';
import { ProcessRecovery } from './containers/ProcessRecovery/ProcessRecovery';
import { Recover } from './containers/Recover/Recover';
import FAQ from './containers/FAQ/FAQ';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path='/create-account' Component={Plans} />
        <Route path='/success' Component={SucessPage} />
        <Route path='/invalid-code' Component={InvalidCodePage} />
        <Route path='/process-recovery/:code' Component={ProcessRecovery} />
        <Route path='/recover' Component={Recover} />
        <Route path='/fail' Component={FailPage} />
        <Route path='/faq' Component={FAQ} />
        <Route path='/ref/:adCode' Component={HomePage} />
        <Route path='/' Component={HomePage} />
        <Route path='*' Component={HomePage} />
      </Routes>
    </Layout>
  );
}

export default App;
