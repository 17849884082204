import { useState } from "react";
import * as Constants from '../../utils/Constants';

type ErrorResponse = {
    error: string
};

type SuccessResponse = {
    status: string
};

export const Recover = () => {
    const [email, setEmail] = useState<string>('');
    const [color, setColor] = useState<string>('red');
    const [hasNotification, setHasNotification] = useState<boolean>(false);
    const [notificationText, setNotificationText] = useState<string>('Password must be 5 characters long');
    
    function handleSubmit() {
        setHasNotification(false);
        setNotificationText('');

        fetch(Constants.POST_RECOVER_ACCT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        })
        .then(response => response.json())
        .then((result: ErrorResponse | SuccessResponse) => {
            if('error' in result) {
                setHasNotification(true);
                setNotificationText(result.error);
                setColor('red');
            }
            else {
                setHasNotification(true);
                setNotificationText('Successfully submitted. Please check your email for a recovery link');
                setColor('green');
            }
        })
        .catch(err => console.error(err));
    }

    let classes = 'notification hide';
    
    if(hasNotification) {
        classes = 'notification';
    }
    return (
        <div className="form-control-recovery">
            <div className={classes} style={{color}}>{notificationText}</div>
            <label htmlFor="email">Email For Password Reset</label>
            <input type="email" name="email" value={email} onChange={evt => setEmail(evt.target.value)} />
            <button className="recovery-button" onClick={handleSubmit}>Submit Password Reset</button>
        </div>
    );
};