import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import chrome from '../../images/chrome.png';
import brave from '../../images/brave.png';
import edge from '../../images/edge.png';
import opera from '../../images/opera.png';
import youTubeLogo from '../../images/youtube-logo.png';
import takeNote from '../../images/take-note.png';
import viewMarkers from '../../images/view-markers.png';
import hoverOverLeftEdge from '../../images/hoverOverLeftEdge.png';
import searchAll from '../../images/searchAll.png';
import searchNotesOnVideo from '../../images/searchNotesOnVideo.png';
import shareVideoNote from '../../images/shareVideoNote.png';
import copyNoteToClipboard from '../../images/copyNoteToClipboard.png';
import shareAllNotesFromVideo from '../../images/shareAll.png';
import videosWithNotes from '../../images/video-suggestions.png';

function HomePage() {
    useEffect(() => {
        window._paq.push(['trackPageView']);
    }, []);

    const { adCode } = useParams();

    if(adCode) {
        window._paq.push(['trackEvent', 'Referral', adCode]);
    }

    return (
        <>
            <div className="hero-section">
                <div className="content">
                    <div className="replaced-element-container icon">
                        <img src={youTubeLogo} alt="YouTube logo" />
                    </div>
                    <h1 className="header-row">YouTube Notes</h1>
                    <a className="chrome-icon" href="https://www.google.com/chrome/dr/download" target="_blank" rel="noreferrer"><img src={chrome} alt="Chrome Browser" /></a>
                    <a className="brave-icon"  href="https://brave.com/download/" target="_blank" rel="noreferrer"><img src={brave} alt="Brave Browser" /></a>
                    <a className="edge-icon" href="https://www.microsoft.com/en-us/edge/download" target="_blank" rel="noreferrer"><img src={edge} alt="Brave Browser" /></a>
                    <a className="opera-icon" href="https://www.opera.com" target="_blank" rel="noreferrer"><img src={opera} alt="Brave Browser" /></a>
                    <div className="wall" />
                </div>
                <div className="hero-text">
                    <p>YouTube Notes. Finally a way to capture notes on YouTube for study or whatever you want.</p>
                    <p>Get notes at particular points in the video and share if you like. Turn YouTube into a university
                    with our easy to use note taking extension.</p>
                    <p>You can also search your notes right in YouTube and navigate to the 
                    notes you have taken with ease.</p>
                    <p>Try it now, tell us what you think. Get it here: <a href="https://chrome.google.com/webstore/detail/youtube-notes/ildeidpnegphiiolihldpgkgfodoknpc">YouTubeNotes extension</a>
                    </p>
                </div>
            </div>
            <h2 className="text-align-center">Here is a quick glance of the features we offer (press ESC to exit any of our screens)</h2>
            <div className="features">
                <p>Hover over the left edge of the screen to view all the features</p>
                <div className="replaced-element-container">
                    <img src={hoverOverLeftEdge} alt="Hover over left edge of screen for feature display" />    
                </div>
                <p>Take note for a video and set a marker</p>
                <div className="replaced-element-container">
                    <img src={takeNote} alt="Take note for video" />    
                </div>
                <p>View note markers</p>
                <div className="replaced-element-container">
                    <img src={viewMarkers} alt="View video markers" />    
                </div>
                <p>Search all your notes across all videos you've created markers on</p>
                <div className="replaced-element-container">
                    <img src={searchAll} alt="Search all notes across all of your markers" />    
                </div>
                <p>Search all your notes in current video</p>
                <div className="replaced-element-container">
                    <img src={searchNotesOnVideo} alt="Search all notes in current video" />    
                </div>
                <p>Share Note from video</p>
                <div className="replaced-element-container">
                    <img src={shareVideoNote} alt="Share Video Note" />    
                </div>
                <p>Copy note to clipboard</p>
                <div className="replaced-element-container">
                    <img src={copyNoteToClipboard} alt="Copy Note To Clipboard" />    
                </div>
                <p>Share all notes from video</p>
                <div className="replaced-element-container">
                    <img src={shareAllNotesFromVideo} alt="Share all notes from video" />    
                </div>
                <p>Suggested videos with notes contain a yellow <span>*</span></p>
                <div className="replaced-element-container">
                  <img src={videosWithNotes} alt="Videos with notes" />    
                </div>
            </div>
        </>
    );
}

export default HomePage;
