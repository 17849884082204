import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Register } from "../Register/Register";
import { Plan } from "../../components/Pricing/Pricing";

export function RegisterCaptchaWrapper(props: { plan: Plan, planWording: string, link: string, onClose: () => void }) {
    return (
        <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey='6Lelh3ElAAAAAKjLAGtpEZv2ulXYStBiO572BwUx'
            scriptProps={{ async: true, defer: true, appendTo: 'body' }}
            container={
                {
                    element: 'recaptcha',
                    parameters: {}
                }
            }>
            <Register {...props} />
        </GoogleReCaptchaProvider>
    );
}