import { useState, useCallback } from "react";
import { Plan } from "../../components/Pricing/Pricing";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as Constants from '../../utils/Constants';

const planMapping = new Map<Plan, number>();

planMapping.set(30, 4);
planMapping.set(15, 3);
planMapping.set(5, 2);
planMapping.set(0, 1);

interface RegistrationError {
    error: string
};

interface RegistrationSuccess {
    _id: string,
    email: string,
    password: string,
    firstName?: string,
    lastName?: string,
    lastLogin: Date,
    dateJoined: Date,
    isActive: boolean,
    accountClosed: boolean,
    closeDate?: Date,
    tier?: 1 | 2 | 3 | 4 | 5,
    spaceUsed?: number
};

export function Register({ plan, planWording, link, onClose }: { plan: Plan, planWording: string, link: string, onClose: () => void }) {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [hasError, setHasError] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [notificationText, setNotificationText] = useState<string>("");

    let registrationClasses = "centered-grid registeration-form show";

    // Create an event handler so you can call the verification on button click event or form submit
    const handleCreateAccount = useCallback(async () => {
        setHasError(false);
        setProcessing(false);
        setNotificationText('');

        if (!executeRecaptcha) {
            setHasError(true);
            setNotificationText('Recaptcha verification failed. Please try again. If problem persists, contact us');
            
            return;
        }

        if ((!email && !password && !firstName && !lastName) || password?.length < 5) {
            setHasError(true);
            setNotificationText('Please supply all fields and ensure the password is at least 5 characters long');

            return;
        }

        const token = await executeRecaptcha('signUp');

        if(!token) {
            setHasError(true);
            setNotificationText('Recaptcha verification failed. Please try again. If problem persists, contact us');

            return;
        }

        setProcessing(true);

        const payload = {
            email, 
            password,
            firstName,
            lastName,
            tier: planMapping.get(plan),
            recaptchaResponse: token
        };

        const response = await fetch(Constants.POST_CREATE_ACCT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const result: RegistrationError | RegistrationSuccess = await response.json();

        console.log(result);

        if('error' in result) {
            setHasError(true);
            setProcessing(false);
            setNotificationText(result.error);

            window._paq.push(['trackEvent', 'Registration', 'Failed_Registration']);

            return;
        }
        else {
            window._paq.push(['trackEvent', 'Registration', 'Successful_Registration']);

            const isRedirect = link.startsWith('https');
            let successMsg = 'Successfully created account, please check your email to verify';

            if (isRedirect) {
                successMsg = 'Successfully created account, please check your email to verify. Redirecting you to the payment page';

                setTimeout(function() {
                    onClose();

                    window.location.href = link;
                }, 3000);
            }

            setNotificationText(successMsg);

            setTimeout(function() {
                onClose();
            }, 3000);
        }

    }, [email, password, firstName, lastName, plan, link, onClose, executeRecaptcha]);

    let notificationFormClass = "form-notification";
    
    if (hasError) {
        notificationFormClass = "form-notification show";
    }
    else if (processing) {
        notificationFormClass = "form-notification form-notification-valid show";
    }

    return (
        <>
            <div className={registrationClasses}>
                <div className="close" onClick={onClose}>X</div>
                <h1>Create Account</h1>
                <div className="form-control">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" value={email} onChange={(evt) => setEmail(evt.target.value)} disabled={processing} />
                </div>
                <div className="form-control">
                    <label htmlFor="password">Password</label>
                    <input type="password" name="password" id="password" value={password} onChange={evt => setPassword(evt.target.value)} disabled={processing} />
                </div>
                <div className="form-control">
                    <label htmlFor="firstName">First Name</label>
                    <input type="firstName" name="firstName" id="firstName" value={firstName} onChange={evt => setFirstName(evt.target.value)} disabled={processing} />
                </div>
                <div className="form-control">
                    <label htmlFor="lastName">Last Name</label>
                    <input type="lastName" name="lastName" id="lastName" value={lastName} onChange={evt => setLastName(evt.target.value)} disabled={processing} />
                </div>
                <div className={notificationFormClass}>{notificationText}</div>
                <div id="recaptcha"></div>
                <button className="registration-btn" onClick={handleCreateAccount} disabled={processing}>{planWording}</button>
            </div>
        </>
    );
}
